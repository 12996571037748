<template>
  <div class="hl-login">
    <div class="hl-login-wrapper">
      <div class="hl-login-name">用户登录</div>
      <van-form @submit="submitForm">
        <van-field v-model="formData.userName"
                   left-icon="user-o"
                   name="用户名"
                   label=""
                   placeholder="请输入用户名"
                   :rules="rules.userName" />

        <van-field v-model="formData.password"
                   left-icon="bag-o"
                   type="password"
                   name="密码"
                   label=""
                   placeholder="请输入密码"
                   :rules="rules.password" />

        <van-row>
          <van-col span="12">
            <van-field v-model="formData.code"
                       name="验证码"
                       label=""
                       placeholder="请输入验证码"
                       :rules="rules.code" />
          </van-col>
          <van-col span="12">
            <img :src="src"
                 @click="getCode"
                 style="height: 36px;margin-left: 10px;;cursor: pointer;">
          </van-col>
        </van-row>
        <van-row style="margin: 1rem;">
          <van-button round
                      block
                      type="info"
                      :loading="loading"
                      loading-text="登录中..."
                      native-type="submit">登 录</van-button>
        </van-row>
      </van-form>
    </div>
  </div>
</template>

<script>
import cryptoAES from '@/utils/cryptoAES';
export default {
  name: 'login',
  components: {},
  data() {
    return {
      loading: false,
      src: '',
      user: this.$store.getters.user,
      key: '',
      formData: {
        userName: '',
        password: '',
        code: ''
      },
      rules: {
        userName: [{ required: true, message: '请填写用户名' }],
        password: [{ required: true, message: '请填写密码' }],
        code: [{ required: true, message: '请填写验证码' }]
      }
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    },
    'user.key': {
      handler(val) {
        this.key = val;
      }
    }
  },
  created() {},
  mounted() {
    this.dynamicKey();
  },
  destroyed() {},
  methods: {
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    //获取验证码
    getCode() {
      this.src =
        process.env.VUE_APP_BASE_API +
        '/api/getKaptchaImage.json?version=' +
        Math.random();
    },
    submitForm() {
      this.loading = true;
      const copyForm = Object.assign({}, this.formData);
      const aesPassword = cryptoAES.encrypt(copyForm.password, this.key);
      copyForm.password = aesPassword;
      this.$store
        .dispatch('user/login', copyForm)
        .then((response) => {
          this.loading = false;
          if (response.success) {
            this.$router.push({ path: '/' });
          } else {
            this.getCode();
            this.$notify(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.getCode();
          this.loading = false;
        });
    },
    //获取AES加密的key
    dynamicKey() {
      this.$store
        .dispatch('user/dynamicKey')
        .then((response) => {
          if (response.success) {
            this.getCode();
          } else {
            this.$notify(response.message);
          }
        })
        .catch((error) => {
          this.$notify('网络异常');
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/theme.scss';

.hl-login {
  width: 100%;
  height: 100%;
  background-image: $loginBg;
  background-size: 100% 100%;
  position: relative;
}
.hl-login-wrapper {
  width: 100%;
  background-color: $loginBgColor;
  position: absolute;
  top: 3rem;
  padding: 1rem 0;
}
.hl-login-name {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
.van-cell {
  margin-bottom: 1.3rem;
}
</style>
